import store from '@/store'
import storage from '@/util/storage'
import {
    createRouter,
    createWebHistory
} from 'vue-router'

/**
 * 定义路由信息
 *
 */
 const routes = [{
    name: 'login',
    path: '/login',
    component: () => import('@/views/login'),
}, {
    name: '首页',
    alias: '/',
    path: '/main',
    component: () => import('@/views/main'),
    children: [
        {
            name: '面板',
            alias: '/',
            path: '/dashboard',
            component: () => import('@/views/system/dashboard'),
        },
        {
            name: '账号管理',
            alias: '/',
            path: '/account',
            // component: () => import('@/views/system/account'),
            children: [
                {
                    name: '账号列表',
                    alias: '/',
                    path: 'account-list',
                    component: () => import('@/views/system/account/account-index'),
                },
                {
                    name: '角色列表',
                    alias: '/',
                    path: 'role-list',
                    component: () => import('@/views/system/account/role-index'),
                },
                {
                    name: '权限列表',
                    alias: '/',
                    path: 'permission-list',
                    component: () => import('@/views/system/account/permission-index'),
                }
            ]
        },
        {
            name: '用户管理',
            alias: '/',
            path: '/user',
            // component: () => import('@/views/system/account'),
            children: [
                {
                    name: '用户列表',
                    alias: '/',
                    path: 'user-list',
                    component: () => import('@/views/system/user/user-index'),
                },
                {
                    name: '患者列表',
                    alias: '/',
                    path: 'patient-list',
                    component: () => import('@/views/system/user/patient-index'),
                },
                {
                    name: '文章列表',
                    alias: '/',
                    path: 'news-list',
                    component: () => import('@/views/system/user/news-index'),
                },
                {
                    name: '分组列表',
                    alias: '/',
                    path: 'group-list',
                    component: () => import('@/views/system/user/group-index'),
                }
            ]
        },
        {
            name: '文章管理',
            alias: '/',
            path: '/news',
            // component: () => import('@/views/system/account'),
            children: [
                {
                    name: '文章列表',
                    alias: '/',
                    path: 'news-list',
                    component: () => import('@/views/system/news/news-index'),
                },
                {
                    name: '文章详情',
                    alias: '/',
                    path: 'news-list/news-detail',
                    component: () => import('@/views/system/news/detail'),
                }
            ]
        },
        {
            name: '医生管理',
            alias: '/',
            path: '/doctor',
            // component: () => import('@/views/system/account'),
            children: [
                {
                    name: '申请列表',
                    alias: '/',
                    path: 'apply-list',
                    component: () => import('@/views/system/doctor/apply-index'),
                },
                {
                    name: '医生列表',
                    alias: '/',
                    path: 'doctor-list',
                    component: () => import('@/views/system/doctor/doctor-index'),
                },
                {
                    name: '医院列表',
                    alias: '/',
                    path: 'hospital-list',
                    component: () => import('@/views/system/doctor/hospital-index'),
                },
                {
                    name: '科室列表',
                    alias: '/',
                    path: 'department-list',
                    component: () => import('@/views/system/doctor/department-index'),
                },
                {
                    name: '职称列表',
                    alias: '/',
                    path: 'title-list',
                    component: () => import('@/views/system/doctor/title-index'),
                },
                {
                    name: '文章列表',
                    alias: '/',
                    path: 'news-list',
                    component: () => import('@/views/system/doctor/news-index'),
                },
                {
                    name: '分组列表',
                    alias: '/',
                    path: 'group-list',
                    component: () => import('@/views/system/doctor/group-index'),
                }
            ]
        },
        {
            name: '订单管理',
            alias: '/',
            path: '/order',
            // component: () => import('@/views/system/account'),
            children: [
                {
                    name: '订单列表',
                    alias: '/',
                    path: 'order-list',
                    component: () => import('@/views/system/order/order-index'),
                },
                {
                    name: '退款列表',
                    alias: '/',
                    path: 'refund-list',
                    component: () => import('@/views/system/order/refund-index'),
                }
            ]
        },
        {
            name: '问诊管理',
            alias: '/',
            path: '/case',
            // component: () => import('@/views/system/account'),
            children: [
                {
                    name: '问诊列表',
                    alias: '/',
                    path: 'case-list',
                    component: () => import('@/views/system/case/case-index'),
                }
            ]
        },
        {
            name: '产品管理',
            alias: '/',
            path: '/product',
            // component: () => import('@/views/system/account'),
            children: [
                {
                    name: '问诊列表',
                    alias: '/',
                    path: 'product-list',
                    component: () => import('@/views/system/product/product-index'),
                }
            ]
        },
        {
            name: '员工管理',
            alias: '/',
            path: '/staff',
            // component: () => import('@/views/system/account'),
            children: [
                {
                    name: '员工列表',
                    alias: '/',
                    path: 'staff-list',
                    component: () => import('@/views/system/staff/staff-index'),
                }
            ]
        },
        {
            name: '问卷管理',
            alias: '/',
            path: '/questionnaire',
            // component: () => import('@/views/system/account'),
            children: [
                {
                    name: '问卷列表',
                    alias: '/',
                    path: 'questionnaire-list',
                    component: () => import('@/views/system/questionnaire/questionnaire-index'),
                },
                {
                    name: '问卷',
                    alias: '/',
                    path: 'detail',
                    component: () => import('@/views/system/questionnaire/detail'),
                },
                {
                    name: '统计分析',
                    alias: '/',
                    path: 'data',
                    component: () => import('@/views/system/questionnaire/data'),
                }
            ]
        },
        {
            name: '会议管理',
            alias: '/',
            path: '/meeting',
            // component: () => import('@/views/system/account'),
            children: [
                {
                    name: '会议列表',
                    alias: '/',
                    path: 'meeting-list',
                    component: () => import('@/views/system/meeting/index'),
                },
                {
                    name: '会议详情',
                    alias: '/',
                    path: 'detail',
                    component: () => import('@/views/system/meeting/detail'),
                },
                {
                    name: '会议扩展信息设置',
                    alias: '/',
                    path: 'extra',
                    component: () => import('@/views/system/meeting/extra'),
                },
                {
                    name: '直播设置',
                    alias: '/',
                    path: 'setting',
                    component: () => import('@/views/system/meeting/setting'),
                },
                {
                    name: '会议合规材料',
                    alias: '/',
                    path: 'compliance',
                    component: () => import('@/views/system/meeting/compliance'),
                }
            ]
        },
        {
            name: '消息管理',
            alias: '/',
            path: '/message',
            // component: () => import('@/views/system/account'),
            children: [
                {
                    name: '消息列表',
                    alias: '/',
                    path: 'message-list',
                    component: () => import('@/views/system/message/index'),
                },
                {
                    name: '消息详情',
                    alias: '/',
                    path: 'detail',
                    component: () => import('@/views/system/message/detail'),
                }
            ]
        },
        {
            name: '打卡管理',
            alias: '/',
            path: '/clockIn',
            // component: () => import('@/views/system/account'),
            children: [
                {
                    name: '打卡列表',
                    alias: '/',
                    path: 'clockIn-list',
                    component: () => import('@/views/system/clockIn/index'),
                },
                {
                    name: '打卡详情',
                    alias: '/',
                    path: 'detail',
                    component: () => import('@/views/system/clockIn/detail'),
                }
            ]
        },
        {
            name: '项目管理',
            alias: '/',
            path: '/project',
            children: [
                {
                    name: '项目列表',
                    alias: '/',
                    path: 'project-list',
                    component: () => import('@/views/system/project/project-index'),
                },
                {
                    name: '客户列表',
                    alias: '/',
                    path: '/customer/customer-list',
                    component: () => import('@/views/system/customer/customer-index.vue'),
                },
                {
                    name: '代表列表',
                    alias: '/',
                    path: '/representative/representative-list',
                    component: () => import('@/views/system/representative/representative-index'),
                },
                {
                    name: '项目列表',
                    alias: '/',
                    path: 'news-list',
                    component: () => import('@/views/system/project/news-index'),
                },
                {
                    name: '分组列表',
                    alias: '/',
                    path: 'group-list',
                    component: () => import('@/views/system/project/group-index'),
                },
                {
                    name: '广告列表',
                    alias: '/',
                    path: '/sale/ad-list',
                    component: () => import('@/views/system/sale/ad-index'),
                },
            ]
        },
        {
            name: '朋友圈管理',
            alias: '/',
            path: '/friends',
            // component: () => import('@/views/system/account'),
            children: [
                {
                    name: '朋友圈列表',
                    alias: '/',
                    path: 'friends-list',
                    component: () => import('@/views/system/friendsCircle/index'),
                },
                {
                    name: '创建朋友圈',
                    alias: '/',
                    path: 'detail',
                    component: () => import('@/views/system/friendsCircle/detail'),
                }
            ]
        },
        {
            name: '标签管理',
            alias: '/',
            path: '/tag',
            // component: () => import('@/views/system/account'),
            children: [
                {
                    name: '标签列表',
                    alias: '/',
                    path: 'tag-list',
                    component: () => import('@/views/system/tag/tag-index'),
                }
            ]
        },
    ]
}]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to) => {
    document.title = '关照运营平台'
    if(to.name == 'login') return true

    if(!store.getters.ISLOGIN){
        if(storage.getSessionString("token")){
            store.dispatch("RECOVERY_ACCOUNT")
        }else{
            router.push({name: "login"})
        }
    }
})

export default router
